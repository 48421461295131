import React, { useEffect, useState } from 'react';
import useSettings from 'src/hooks/useSettings';
import axios from 'src/utils/axiosApi';

const Sentence = ({ text, placeholder = '' }) => {
    const { settings } = useSettings();
    const [sentence, setSentence] = useState(placeholder);

    useEffect(() => {
        let isMounted = true;
        const getSentence = axios
            .post('/SentenceGetSentence', {
                Language: settings.language,
                Key: text
            })
            .then(({ data: sentence }) => {
                if (isMounted) {
                    setSentence(sentence.Text);
                }
            })
            .catch(err => {
                console.error(err);
                if (isMounted) {
                    setSentence(placeholder);
                }
            });
        return () => {
            isMounted = false;
        };
    }, [settings.language]);

    return sentence;
};

export default Sentence;
