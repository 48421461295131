import React, { useState, useEffect, useRef } from 'react';
import {
    Badge,
    Box,
    FormControlLabel,
    IconButton,
    Popover,
    SvgIcon,
    Tooltip,
    RadioGroup,
    Radio,
    Typography,
    makeStyles
} from '@material-ui/core';
import { Flag as FlagIcon } from 'react-feather';
import axios from 'src/utils/axiosApi';
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles(theme => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 200,
        padding: theme.spacing(2)
    }
}));

const Languages = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();
    const [isOpen, setOpen] = useState(false);

    const [language, setLanguage] = useState(settings.language);
    const [languagesList, setLanguagesList] = useState({});

    useEffect(() => {
        async function getAllLanguages() {
            try {
                const { data: allLanguages } = await axios.get('/LanguageList');
                setLanguagesList(allLanguages);
            } catch (err) {
                console.error(err);
            }
        }
        getAllLanguages();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = event => {
        const targetLang = languagesList.filter(
            lang => lang.IsoCode == event.target.value
        )[0];
        saveSettings({ language: targetLang });
        setLanguage(targetLang);
    };

    return (
        <>
            <Badge color="secondary" classes={{ badge: classes.badge }}>
                <IconButton color="inherit" onClick={handleOpen} ref={ref}>
                    <SvgIcon fontSize="small">
                        <FlagIcon />
                    </SvgIcon>
                </IconButton>
            </Badge>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box px={1}>
                    {languagesList.length > 0 && (
                        <RadioGroup
                            aria-label="language"
                            name="language"
                            value={language.IsoCode}
                            onChange={handleChange}
                        >
                            {languagesList.map(lang => {
                                return (
                                    <FormControlLabel
                                        key={lang.IsoCode}
                                        value={lang.IsoCode}
                                        control={<Radio />}
                                        label={lang.Name}
                                    />
                                );
                            })}
                        </RadioGroup>
                    )}
                </Box>
            </Popover>
        </>
    );
};

export default Languages;
