export const deviceCode = 'web';

export const contactsTypes = [
    {
        id: 1,
        type: 'Fax'
    },
    {
        id: 2,
        type: 'MobilePhone'
    },
    {
        id: 3,
        type: 'LandlinePhone'
    },
    {
        id: 4,
        type: 'Email'
    },
    {
        id: 5,
        type: 'Skype'
    },
    {
        id: 6,
        type: 'Website'
    }
];

export const licenceTypes = {
    Free: {
        id: 1,
        value: 'Free'
    },
    Persistent: {
        id: 2,
        value: 'Persistent'
    },
    MonthlySubcrition: {
        id: 3,
        value: 'MonthlySubcrition'
    },
    PersistentWithAnnualSubscription: {
        id: 4,
        value: 'PersistentWithAnnualSubscription'
    }
};

export const paymentOptions = {
    Immediate: 'Immediate',
    Postponed: 'Persistent'
};
