import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Typography,
    FormHelperText,
    TextField,
    Box,
    makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik, Form } from 'formik';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import axios from 'src/utils/axiosApi';
import wait from 'src/utils/wait';
import Sentence from './Sentence';

const useStyles = makeStyles(() => ({
    dialog: {
        width: 600,
        maxWidth: '100%'
    }
}));

function AgreementsPopup({ agreementsToShow, handleAgreementSubmit }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [agreements, setAgreements] = useState([]);
    const { settings } = useSettings();
    const { getSession } = useAuth();

    const handleClose = () => {
        handleAgreementSubmit('handleclose');
        //setOpen(false);
    };

    useEffect(() => {
        function getAgreementContent() {
            if (agreementsToShow.length > 0) {
                Promise.all(
                    //loop throught agreements base obj
                    agreementsToShow.map(agreement => {
                        //get agreement text
                        return axios.post('/AgreementText', {
                            Session: getSession(),
                            Agreement: agreement.Agreement,
                            Language: settings.language
                        });
                    })
                ).then(agreementsContentReqs => {
                    let agreementsWithContent = [];
                    //loop throught agreement contents array of response
                    agreementsContentReqs.map(agreementContentReq => {
                        const agreementContent = agreementContentReq.data;
                        //get the single agreement base obj based of curr response
                        let singleAgreement = agreementsToShow.filter(
                            agreement =>
                                agreement.Agreement.Id ==
                                agreementContent.Agreement.Id
                        );
                        //add to the single base agreement the title and the content
                        singleAgreement[0].Agreement.Title =
                            agreementContent.Title;
                        singleAgreement[0].Agreement.Content =
                            agreementContent.Content;

                        //push the complete agreement to the agreement array
                        agreementsWithContent.push(singleAgreement[0]);
                    });
                    //finally set the final agreements (change the state only one time)
                    setAgreements(agreementsWithContent);
                });
            }
        }

        getAgreementContent();
    }, [agreementsToShow]);

    const getAgreementsInitialValues = () => {
        let initialAgreements = [];
        agreementsToShow.map(agreement => {
            if (agreement.Accepted == true) {
                initialAgreements.push('agreement' + agreement.Agreement.Id);
            }
        });
        return initialAgreements;
    };

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper">
            <Formik
                initialValues={{
                    agreementsCheckboxes: getAgreementsInitialValues()
                }}
                //function on form submit
                onSubmit={fields => {
                    const acceptedAgreements = fields.agreementsCheckboxes;
                    Promise.all(
                        //map throught the showed agreements
                        agreements.map(agreement => {
                            //check if the current agreement is one of the checked ones
                            agreement.Accepted = acceptedAgreements.includes(
                                'agreement' + agreement.Agreement.Id
                            )
                                ? true
                                : false;
                            //save the user agreement state
                            return axios.post('/UserSaveAgreement', {
                                Session: getSession(),
                                Agreement: agreement
                            });
                        })
                    )
                        .then(res => {
                            //close the dialog
                            setOpen(false);
                            //wait the animation time and then return the agreement popup result
                            wait(300).then(res => {
                                handleAgreementSubmit(true);
                            });
                        })
                        //catch any errors
                        .catch(err => {
                            console.error(err);
                        });
                }}
            >
                {({ values, handleChange }) => (
                    <Form className={classes.dialog}>
                        <DialogTitle disableTypography id="scroll-dialog-title">
                            <Typography variant="h4">
                                <Sentence text="login termini e condizioni" />
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            {agreements.length > 0 &&
                                agreements.map(agreement => {
                                    return (
                                        <Accordion
                                            key={
                                                'agreement-' +
                                                agreement.Agreement.Id
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <FormControlLabel
                                                    onClick={event =>
                                                        event.stopPropagation()
                                                    }
                                                    onFocus={event =>
                                                        event.stopPropagation()
                                                    }
                                                    control={
                                                        <Checkbox
                                                            onChange={
                                                                handleChange
                                                            }
                                                            required
                                                            name="agreementsCheckboxes"
                                                            value={
                                                                'agreement' +
                                                                agreement
                                                                    .Agreement
                                                                    .Id
                                                            }
                                                            checked={values.agreementsCheckboxes.includes(
                                                                'agreement' +
                                                                    agreement
                                                                        .Agreement
                                                                        .Id
                                                            )}
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {
                                                                agreement
                                                                    .Agreement
                                                                    .Title
                                                            }
                                                            <span
                                                                style={{
                                                                    color: 'red'
                                                                }}
                                                            >
                                                                {agreement
                                                                    .Agreement
                                                                    .Mandatory &&
                                                                    ' *'}
                                                            </span>
                                                        </Typography>
                                                    }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography
                                                    dangerouslySetInnerHTML={{
                                                        __html: agreement.Agreement.Content.replace(
                                                            /(?:\r\n|\r|\n)/g,
                                                            '<br />'
                                                        )
                                                    }}
                                                    color="textSecondary"
                                                ></Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                            >
                                <Sentence text="accept_continue" />
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default AgreementsPopup;
