/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    makeStyles
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {
    ShoppingCart as ShoppingCartIcon,
    Users as UsersIcon,
    Package as PackageIcon,
    ShoppingBag as ShoppingBagIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

function renderNavItems({ items, pathname, depth = 0 }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.restrict) {
        return acc;
    }

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
                //restrict={item.restrict}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
                //restrict={item.restrict}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useAuth();

    const sections = [
        {
            subheader: 'Prodotti',
            items: [
                {
                    title: 'Prodotti',
                    icon: PackageIcon,
                    href: '/app/products'
                },
                {
                    title: 'Carrello',
                    icon: ShoppingCartIcon,
                    href: '/app/cart',
                    restrict:
                        !user.PartOfCompany || user.CompanyAdministrator
                            ? false
                            : true
                }
            ]
        },
        {
            subheader: 'Azienda',
            items: [
                {
                    title: 'Azienda',
                    icon: UsersIcon,
                    href: '/app/company'
                },
                {
                    title: 'Gestione utenti',
                    icon: BusinessIcon,
                    href: '/app/usermanagement',
                    restrict: !user.CompanyAdministrator
                },
                {
                    title: 'Licenze',
                    icon: FileCopyOutlinedIcon,
                    href: '/app/licenses'
                },
                {
                    title: 'Ordini',
                    icon: ShoppingBagIcon,
                    href: '/app/orders',
                    restrict:
                        !user.PartOfCompany || user.CompanyAdministrator
                            ? false
                            : true
                }
            ]
        }
    ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/app">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display="flex" justifyContent="center">
                        <RouterLink to="/app/account">
                            <Avatar alt="User" className={classes.avatar} />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link
                            component={RouterLink}
                            to="/app/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none"
                        >
                            {user.Name && user.Name}{' '}
                            {user.LastName && user.LastName}
                        </Link>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {sections.map(section => (
                        <List
                            key={section.subheader}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname
                            })}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
