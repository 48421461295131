import Cookies from 'js-cookie';
import axios from 'axios';

const setSession = session => {
    if (session) {
        Cookies.set('session', JSON.stringify(session));
    } else {
        Cookies.remove('session');
    }
};

const instance = axios.create({
    baseURL: 'https://services.dnsistemi.com/api',
    //baseURL: '/api',
    //timeout: 1000
    headers: {
        'Content-Type': 'application/json'
    },
    transformResponse: [
        function(data) {
            let jsonData = JSON.parse(data);
            if (jsonData?.Result && jsonData.Result != 'OK') {
                throw { message: jsonData.Result };
            }

            //reset the session on every call with a new session
            if (jsonData?.Session) {
                setSession(jsonData?.Session);
            }
            if (jsonData?.NewSession) {
                setSession(jsonData?.NewSession);
            }

            return jsonData;
        }
    ]
});

export default instance;
