import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axiosApi';
import Cookies from 'js-cookie';

import { deviceCode } from 'src/config';

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    company: null
};

const isValidSession = session => {
    if (!session) {
        return false;
    }
    return true;
};

export const setSession = session => {
    if (session) {
        Cookies.set('session', JSON.stringify(session));
    } else {
        Cookies.remove('session');
    }
};

export const getSession = () => {
    if (Cookies.get('session')) {
        return JSON.parse(Cookies.get('session'));
    } else {
        return null;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated, user } = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'USER': {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case 'COMPANY': {
            const { company } = action.payload;
            return {
                ...state,
                company
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    loginComplete: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    setNewCompany: () => {},
    setNewUser: () => {},
    getSession: () => {}
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    //Do login action
    const login = async (company, username, password) => {
        try {
            //do login
            const { data: userLogin } = await axios.post('/UserLogin', {
                CompanyName: company,
                Username: username,
                Password: password,
                DeviceCode: deviceCode
            });

            //return login response
            return userLogin;
        } catch (err) {
            console.error(err);
            return { LoginResult: 'InternalServerError', Session: null };
        }
    };

    //Do complete login to dashboard
    const loginComplete = async session => {
        try {
            //do login complete
            const { data: userBySession } = await axios.post(
                '/UserGetBySession',
                session
            );

            //dispatch login with user obj
            //untill user is not set cannot go to dashboard
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: userBySession.User
                }
            });

            //get company data by session
            const { data: companyBySession } = await axios.post(
                '/UserCompany',
                userBySession.NewSession
            );

            dispatch({
                type: 'COMPANY',
                payload: {
                    company: companyBySession?.Company || null
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    //Update user state
    const setNewUser = user => {
        dispatch({
            type: 'USER',
            payload: {
                user: user
            }
        });
    };

    //Update user state
    const setNewCompany = company => {
        dispatch({
            type: 'COMPANY',
            payload: {
                company
            }
        });
    };

    //Do logout action
    const logout = () => {
        //dispatch logout action
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    //Do register action
    const register = async ({ username, email, password, language }) => {
        const response = await axios.post('/UserCreate', {
            Username: username,
            Email: email,
            Password: password,
            Company: null,
            Language: language
        });

        const { Result } = response.data;

        if (Result == 'OK') {
            return {
                success: true,
                title: 'popup successo',
                message: 'registrazione successo'
            };
        } else if (Result == 'UserExists') {
            return {
                success: false,
                title: 'popup errore',
                message: 'registrazione utente esistente'
            };
        } else if (Result == 'EmailIsAlreadyUsed') {
            return {
                success: false,
                title: 'popup errore',
                message: 'error_email_already_used'
            };
        } else {
            return {
                success: false,
                title: 'popup errore',
                message: result
            };
        }
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                //get the session from cookies
                const session = getSession();

                //check if session exists
                if (!session) {
                    //throw { type: 'warn', message: 'Sessione non esistente' };
                    throw { type: 'warning', message: '' };
                }

                //check if session exists
                if (!isValidSession(session)) {
                    throw {
                        type: 'warning',
                        message: "Sessione non valida, rifare l'accesso"
                    };
                }

                //get user data by session
                const { data: userBySession } = await axios.post(
                    '/UserGetBySession',
                    session
                );

                if (userBySession.User != null) {
                    //dispatch initialise action
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user: userBySession.User
                        }
                    });

                    //hydrate company only if user is part of one
                    if (userBySession.User?.PartOfCompany) {
                        //get company data by session
                        const { data: companyBySession } = await axios.post(
                            '/UserCompany',
                            getSession()
                        );

                        dispatch({
                            type: 'COMPANY',
                            payload: {
                                company: companyBySession.Company
                            }
                        });
                    }
                }
            } catch (err) {
                if (err.message) {
                    console.warn(err.message);
                }
                //dispatch starting initialise action
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
                dispatch({
                    type: 'COMPANY',
                    payload: {
                        company: null
                    }
                });
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                loginComplete,
                logout,
                register,
                setNewUser,
                getSession,
                setNewCompany
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
