import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    Avatar,
    Box,
    ButtonBase,
    Menu,
    MenuItem,
    makeStyles,
    Typography
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import Sentence from 'src/components/Sentence';
import AgreementsPopup from 'src/components/AgreementsPopup';
import axios from 'src/utils/axiosApi';

const useStyles = makeStyles(theme => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
    popover: {
        width: 200
    }
}));

const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const { user, getSession, logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    //agreements
    const [showAgreements, setShowAgreements] = useState(false);
    const [agreementsToShow, setAgreementsToShow] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            history.push('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                variant: 'error'
            });
        }
    };

    const handleAgreements = () => {
        axios
            .post('/UserAgreementsList', getSession())
            .then(({ data }) => {
                //extract the agreements
                const agreementsList = data.Agreements;
                if (agreementsList.length > 0) {
                    setAgreementsToShow(agreementsList);
                    setShowAgreements(true);
                }
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleAgreementSubmit = res => {
        if (res) {
            setShowAgreements(false);
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <Avatar alt="User" className={classes.avatar} />
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem component={RouterLink} to="/app/account">
                    <Sentence text="private_my_profile" />
                </MenuItem>
                <MenuItem onClick={handleAgreements}>
                    <Sentence text="login termini e condizioni" />
                </MenuItem>
                {showAgreements && (
                    <AgreementsPopup
                        agreementsToShow={agreementsToShow}
                        handleAgreementSubmit={handleAgreementSubmit}
                    />
                )}
                <MenuItem onClick={handleLogout}>
                    <Sentence text="logout" />
                </MenuItem>
            </Menu>
        </>
    );
};

export default Account;
